<template>
  <div v-if="!loading">
    <h1 class="main-header">
      <translate translate-context="base_plan" :translate-params="{id: basePlan.id, name: basePlan.name.en}">Edit Base
        Plan
        #%{id} -
        %{name}
      </translate>
    </h1>
    <Form :data="basePlan" @save="onSave" :save_text="$pgettext('form','Save')"/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Form from "@/domain/region/basePlan/Form.vue";
import {RecordNotFound, SuccessNotification} from "@/domain/core/exception/exceptions";

export default {
  name: 'EditBasePlan',
  components: {Form},
  data() {
    return {
      loading: true,
    }
  },
  async mounted() {
    this.setRegionId(this.$route.params.region)
    this.setCategoryId(this.$route.params.category)
    try {
      await this.fetchBasePlan(this.$route.params.id)
    } catch (e) {
      if (e instanceof RecordNotFound) {
        await this.$router.push({name: 'base_plan.list', params: {region: this.regionId, category: this.categoryId}})
      }
    }
    this.loading = false
  },
  computed: {
    ...mapGetters('region', [
      'basePlan',
      'regionId',
      'categoryId'
    ]),
  },
  methods: {
    ...mapActions('region', [
      'setRegionId',
      'setCategoryId',
      'fetchBasePlan',
      'updateBasePlan',
    ]),
    async onSave(data) {
      await this.updateBasePlan({id: this.basePlan.id, data: data})
      await this.$router.push({name: 'base_plan.list', params: {region: this.regionId, category: this.categoryId}})
      throw new SuccessNotification(this.$pgettext('base_plan', 'Base Plan updated!'))
    }
  }
}
</script>