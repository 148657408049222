<template>
  <CCard>
    <CCardHeader>
      <div class="card-header-actions">
        <CButton variant="outline" color="success" @click="onReset" ref="reset-button" v-translate
                 translate-context="form" class="mr-2">Reset
        </CButton>
        <CButton color="primary" @click="onSave" ref="save-button">{{ save_text }}</CButton>
      </div>
    </CCardHeader>
    <CCardBody class="p-0">
      <CContainer fluid class="grid-stripped">
        <PFRow :label="$pgettext('base_plan.label', 'Name')">
          <PTranslatableTextInput v-model="form.name"/>
        </PFRow>
        <PFRow :label="$pgettext('base_plan.label', 'Description')">
          <PTranslatableCkeditor v-model="form.description"/>
        </PFRow>
        <PFRow :label="$pgettext('base_plan.label', 'Price')">
          <CInput v-model="form.price"/>
        </PFRow>
        <PFRow :label="$pgettext('base_plan.label', 'Recommended Participants')">
          <CInput v-model="form.recommended_participants"/>
        </PFRow>
        <PFRow :label="$pgettext('base_plan.label', 'Single Use')">
          <CSwitch class="mx-1" color="primary" variant="3d" size="sm" :checked.sync="form.single_use"/>
        </PFRow>
        <PFRow :label="$pgettext('base_plan.label', 'Is Active')">
          <CSwitch class="mx-1" color="primary" variant="3d" size="sm" :checked.sync="form.is_active"/>
        </PFRow>
        <PFRow :label="$pgettext('base_plan.label', 'Is Default')">
          <CSwitch class="mx-1" color="primary" variant="3d" size="sm" :checked.sync="form.is_default"/>
        </PFRow>
        <PFRow :label="$pgettext('base_plan.create.label','Tags')" size="12x12">
          <PTagInput v-model="form.tags" :allowed_tags="basePlanTags.options()"/>
        </PFRow>
        <PFRow :label="$pgettext('base_plan.create.label','Template')" size="12x12">
          <PSelectKeyValue :options="searchTemplate" :value.sync="form.template" placeholder="Select Template"/>
        </PFRow>
        <PFRow :label="$pgettext('base_plan.create.label','Vertical')" size="12x12">
          <PSelectKeyValue :options="searchVertical" :value.sync="form.vertical" placeholder="Select Vertical"/>
        </PFRow>
        <PFRow :label="$pgettext('base_plan.create.label','Clone from Plan')" size="12x12">
          <PlanModalSelector @input="onSelectPlan" ref="planModalSelector"/>
          <CButton @click="openPlanModalSelector" v-translate translate-context="base_plan.create.actions">
            Search Plan
          </CButton>
        </PFRow>
        <PFRow :label="$pgettext('plan.label','Included')">
          <CInput v-model="form.plan.num_photo_included" type="number"/>
        </PFRow>
        <PFRow :label="$pgettext('plan.label','Price for extra media')">
          <CInput v-model="form.plan.extra_photo_price" type="number"/>
        </PFRow>
        <PFRow :label="$pgettext('plan.label','Max uploadable media')">
          <CInput v-model="form.plan.num_max_photo" type="number"/>
        </PFRow>
        <PFRow :label="$pgettext('plan.label','Max participants (for plan choice)')">
          <CInput v-model="form.plan.max_participants" type="number"/>
        </PFRow>
        <PFRow :label="$pgettext('plan.label','Is Promo')">
          <CSwitch class="mx-1" color="primary" variant="3d" size="sm" :checked.sync="form.plan.is_promo"/>
        </PFRow>
      </CContainer>
    </CCardBody>
  </CCard>

</template>

<script>
import PFRow from "@/domain/core/components/PFRow.vue";
import PTranslatableTextInput from "@/domain/core/components/PTranslatableTextInput.vue";
import PTranslatableCkeditor from "@/domain/core/components/PTranslatableCkeditor.vue";
import PSelectKeyValue from "@/domain/core/components/PSelectKeyValue.vue";
import {mapActions} from "vuex";
import PlanModalSelector from "@/domain/plans/components/PlanModalSelector.vue";
import PTagInput from "@/domain/core/components/PTagInput.vue";
import basePlanTags from "@/domain/core/constant/basePlanTags";

export default {
  name: "Form",
  computed: {
    basePlanTags() {
      return basePlanTags
    }
  },
  components: {
    PTagInput,
    PlanModalSelector,
    PSelectKeyValue,
    PTranslatableCkeditor,
    PTranslatableTextInput,
    PFRow
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    save_text: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      form: {
        name: {},
        description: {},
        price: '',
        tags: [],
        recommended_participants: '',
        is_active: true,
        is_default: false,
        single_use: false,
        template: null,
        vertical: null,
        plan: {
          num_photo_included: null,
          extra_photo_price: null,
          num_max_photo: null,
          max_participants: null,
          is_promo: false,
        },
      },
    }
  },
  async mounted() {
    this.onReset()
  },
  emits: ['save'],
  methods: {
    ...mapActions('region', [
      'searchVertical',
      'searchTemplate'
    ]),
    onReset() {
      this.form = {...this.data}
    },
    async onSave() {
      this.$emit('save', this.form)
    },
    openPlanModalSelector() {
      this.$refs.planModalSelector.open()
    },
    onSelectPlan(plan) {
      this.form.plan = plan
    }
  }
}
</script>